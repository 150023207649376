import { render, staticRenderFns } from "./goodsShareInfo-companyList.vue?vue&type=template&id=724ca244&scoped=true&"
import script from "./goodsShareInfo-companyList.vue?vue&type=script&lang=js&"
export * from "./goodsShareInfo-companyList.vue?vue&type=script&lang=js&"
import style0 from "./goodsShareInfo-companyList.vue?vue&type=style&index=0&id=724ca244&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724ca244",
  null
  
)

export default component.exports