
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="80%"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'120px'" :inline="true">
        <el-form-item label="商家" prop="merchantId">
          <el-select
            size="medium"
            v-model="formModel.merchantId"
            filterable
            placeholder="请选择"
            style="width:280px"
          >
            <el-option
              v-for="erchantInfo in erchantInfoResult"
              :key="erchantInfo.id"
              :label="erchantInfo.merchantName"
              :value="erchantInfo.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" prop="cost">
          <el-input v-model="formModel.cost" placeholder="请输入价格" style="width:300px">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>        
        <el-row>
          <el-form-item label="是否启用" prop="isOpen">
            <div style="width:300px">
            <el-radio-group v-model="formModel.isOpen">
              <el-radio :label="true">启用</el-radio>
              <el-radio :label="false">关闭</el-radio>
            </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="分配类型" prop="merchantDistributionType">
            <div style="width:300px">
            <el-radio-group v-model="formModel.merchantDistributionType">
              <el-radio label="1">固定金额</el-radio>
              <el-radio label="2">比例</el-radio>
            </el-radio-group>
            </div>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="商户分成" prop="merchantShare">
            <el-input v-model="formModel.merchantShare" placeholder="请输入" style="width:300px">
              <template slot="append">{{formModel.merchantDistributionType=='1' ? '元' : '%'}}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="平台分成" prop="platformShare">
            <el-input v-model="formModel.platformShare" placeholder="请输入" style="width:300px">
              <template slot="append">{{formModel.merchantDistributionType=='1' ? '元' : '%'}}</template>
            </el-input>
          </el-form-item>
        </el-row>
        <!-- <el-row>
          <el-form-item label="学校分成" prop="schoolShare">
            <el-input v-model="formModel.schoolShare" placeholder="请输入" style="width:300px">              
              <template slot="append">{{formModel.merchantDistributionType=='1' ? '元' : '%'}}</template>
            </el-input>
          </el-form-item>
        </el-row> -->
        <el-row>
          <el-form-item label="销售人员" prop="salesmanId">
            <el-select
              v-model="formModel.salesmanId"
              filterable
              clearable
              placeholder="请选择"
              style="width:300px"
            >
              <el-option
                v-for="salesmanInfo in salesmanInfoResult"
                :key="salesmanInfo.id"
                :label="salesmanInfo.merchantName"
                :value="salesmanInfo.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售人员分成" prop="salesmanShare">
            <el-input v-model="formModel.salesmanShare" placeholder="请输入" style="width:300px">              
              <template slot="append">{{formModel.merchantDistributionType=='1' ? '元' : '%'}}</template>
            </el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="赠送会员时长" prop="giveVipMouth">
            <el-input v-model="formModel.giveVipMouth" placeholder="请输入" style="width:300px">   
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="返利金额" prop="givePayerMoney">
            <el-input v-model="formModel.givePayerMoney" placeholder="请输入" style="width:300px">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-row>
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import goodsShareInfoApi from "@/api/merchant/goodsShareInfo";
import merchantInfoApi from "@/api/merchant/merchantInfo";

export default {
  props: ["businessKey", "title", "companyId", "goodsId"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        merchantId: [{ required: true, message: "请选择商户", trigger: "blur" }],
        cost: [{ required: true, message: "请设置价格", trigger: "blur" }],
        isOpen: [{ required: true, message: "请选择", trigger: "blur" }],
        merchantDistributionType: [{ required: true, message: "请选择分配类型", trigger: "blur" }],
        merchantShare: [{ required: true, message: "请填写", trigger: "blur" }],
        platformShare: [{ required: true, message: "请填写", trigger: "blur" }],
        salesmanShare: [{ required: true, message: "请填写", trigger: "blur" }],
        giveVipMouth: [{ required: true, message: "请填写", trigger: "blur" }],
        givePayerMoney: [{ required: true, message: "请填写", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      popedomList: [],
      deviceAddressData: [],
      erchantInfoResult: [],
      salesmanInfoResult:[]
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("type","1");
    merchantInfoApi.list(formData).then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.erchantInfoResult = jsonData.data;
      }
    });

    var formData2 = new FormData();
    formData2.append("type","3");
    merchantInfoApi.list(formData2).then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.salesmanInfoResult = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          //分成方式需要相同
          var  distributionType = self.formModel.merchantDistributionType;

          self.formModel.schoolDistributionType = distributionType;
          self.formModel.platformDistributionType = distributionType;
          self.formModel.salesmanDistributionType = distributionType;

          //学校分成默认为0
          self.formModel.schoolShare = 0;

          var total = parseFloat(self.formModel.merchantShare) 
                    + parseFloat(self.formModel.salesmanShare) 
                    + parseFloat(self.formModel.platformShare);

          var cost  = self.formModel.cost*100 + "";

          if(distributionType=='1'){
            //分成金额是否等于商品金额
            if((total*100).toFixed(0) != cost){
              self.$message.warning("分成金额相加不等于商品金额!");
              return;
            }
          }
          else{
            //分成百分比相加要等于100
            if(total.toFixed(0) != "100"){
              self.$message.warning("分成比例相加不等于100!");                
              return;
            }
          }

          (function () {
            var id = self.formModel.id;

            //self.formModel.companyId = self.businessKey;
            self.formModel.schoolId = self.companyId;
            self.formModel.goodsId = self.goodsId;           

            if (id == null || id.length == 0) {
              return goodsShareInfoApi.add(self.formModel);
            } else {
              return goodsShareInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;
    (function () {
      if (self.businessKey == null) {
        return goodsShareInfoApi.create(self.goodsId);
      } else {
        return goodsShareInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          if (self.formModel.sex == null) {
            self.formModel.sex = "";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>