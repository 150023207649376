
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="商品" prop="goodsId">
          <el-select
            v-model="formModel.goodsId"
            filterable
            placeholder="请选择"
            style="width:280px"
            @change="getCost"
          >
            <el-option
              v-for="goodsInfo in goodsInfoResult"
              :key="goodsInfo.id"
              :label="goodsInfo.name"
              :value="goodsInfo.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家" prop="merchantId">
          <el-select
            v-model="formModel.merchantId"
            filterable
            placeholder="请选择"
            style="width:280px"
          >
            <el-option
              v-for="erchantInfo in erchantInfoResult"
              :key="erchantInfo.id"
              :label="erchantInfo.merchantName"
              :value="erchantInfo.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格" prop="cost">
          <el-input v-model="formModel.cost" placeholder="请输入价格" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="商户分成" prop="merchantShare">
          <el-input v-model="formModel.merchantShare" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="分配类型" prop="merchantDistributionType">
          <el-radio-group v-model="formModel.merchantDistributionType">
            <el-radio label="1">固定金额</el-radio>
            <el-radio label="2">比例</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="平台分成" prop="platformShare">
          <el-input v-model="formModel.platformShare" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="分配类型" prop="platformDistributionType">
          <el-radio-group v-model="formModel.platformDistributionType">
            <el-radio label="1">固定金额</el-radio>
            <el-radio label="2">比例</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学校分成" prop="schoolShare">
          <el-input v-model="formModel.schoolShare" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="分配类型" prop="schoolDistributionType">
          <el-radio-group v-model="formModel.schoolDistributionType">
            <el-radio label="1">固定金额</el-radio>
            <el-radio label="2">比例</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="销售人员" prop="salesmanId">
          <el-select
            size="mini"
            v-model="formModel.salesmanId"
            filterable
            placeholder="请选择"
            style="width:280px"
          >
            <el-option
              v-for="salesmanInfo in salesmanInfoResult"
              :key="salesmanInfo.id"
              :label="salesmanInfo.merchantName"
              :value="salesmanInfo.id"
            ></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item label="销售人员分成" prop="salesmanShare">
          <el-input v-model="formModel.salesmanShare" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="分配类型" prop="salesmanDistributionType">
          <el-radio-group v-model="formModel.salesmanDistributionType">
            <el-radio label="1">固定金额</el-radio>
            <el-radio label="2">比例</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="赠送会员时长(天)" prop="giveVipMouth">
          <el-input v-model="formModel.giveVipMouth" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="返利金额(元)" prop="givePayerMoney">
          <el-input v-model="formModel.givePayerMoney" placeholder="请输入" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="isOpen">
          <el-radio-group v-model="formModel.isOpen">
            <el-radio :label="true">启用</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import goodsShareInfoApi from "@/api/merchant/goodsShareInfo";
import merchantInfoApi from "@/api/merchant/merchantInfo";
import goodsInfoApi from "@/api/base/goodsInfo";

export default {
  props: ["title", "schoolIdList"],
  data() {
    return {
      formModel: { cost: "" },
      ruleValidate: {
        goodsId: [{ required: true, message: "请选择商品", trigger: "blur" }],
        merchantId: [
          { required: true, message: "请选择商户", trigger: "blur" },
        ],
        giveVipMouth:[{ required: true, message: "请填写赠送时长", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      popedomList: [],
      deviceAddressData: [],
      erchantInfoResult: [],
      salesmanInfoResult: [],
      goodsInfoResult: [],
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("type", null);
    goodsInfoApi.typeList({ type: null }).then((response) => {
      var jsonData = response.data;
      this.goodsInfoResult = jsonData.data;
    });
    // goodsInfoApi.typeList(formData).then(function (response) {
    //   var jsonData = response.data;
    //   if (jsonData.result) {
    //     self.goodsInfoResult = jsonData.data;
    //   }
    // });

    var formData2 = new FormData();
    formData2.append("type", "1");
    merchantInfoApi.list(formData2).then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.erchantInfoResult = jsonData.data;
      }
    });

    // var formData3 = new FormData();
    // formData3.append("type", "3");
    // merchantInfoApi.list(formData3).then(function (response) {
    //   var jsonData = response.data;
    //   if (jsonData.result) {
    //     self.salesmanInfoResult = jsonData.data;
    //   }
    // });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;
      self.formModel.schoolIdList = self.schoolIdList;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            return goodsShareInfoApi.batchUpdate(self.formModel);
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    getCost(value) {
      var formData = new FormData();
      formData.append("goodsId", value);
      this.loading = true;
      goodsInfoApi.findByGoodsId(formData).then((response) => {
        var jsonData = response.data;
        if (jsonData.result) {
          this.formModel.cost = jsonData.data.cost;
          this.loading = false;
        }
      });
    },
  },
  mounted: function () {},
};
</script>