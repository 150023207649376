<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="90%"
  >
    <div>
      <el-divider></el-divider>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >刷新</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="batchSetSalesman()"
            :loading="loading"
          >设置销售人员</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label="序号" :index="1" width="50"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称" width="270" show-overflow-tooltip></el-table-column>
        <el-table-column prop="merchantName" label="商户名称" width="120"></el-table-column>
        <el-table-column prop="cost" label="价格" width="80">
          <template slot-scope="{row}">
            <span v-if="row.cost != null">{{row.cost}}元</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="merchantShare" label="商户分成" width="100">
          <template slot-scope="{row}">
            <span
              v-if="row.merchantShare != null && row.merchantShare != '' && row.merchantDistributionType == 1"
            >{{row.merchantShare}}元</span>
            <span
              v-else-if="row.merchantShare != null && row.merchantShare != '' && row.merchantDistributionType == 2"
            >{{row.merchantShare}}%</span>
            <span v-else>{{row.merchantShare}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="merchantDistributionType" label="类型" width="80">
            <template slot-scope="{row}">
              <span v-if="row.merchantDistributionType == 1">金额</span>
              <span v-else-if="row.merchantDistributionType == 2">比例</span>
              <span v-else></span>
            </template>
        </el-table-column>-->
        <el-table-column prop="platformShare" label="平台分成" width="100">
          <template slot-scope="{row}">
            <span
              v-if="row.platformShare != null && row.platformShare != '' && row.platformDistributionType == 1"
            >{{row.platformShare}}元</span>
            <span
              v-else-if="row.platformShare != null && row.platformShare != '' && row.platformDistributionType == 2"
            >{{row.platformShare}}%</span>
            <span v-else>{{row.platformShare}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="platformDistributionType" label="类型" width="80">
            <template slot-scope="{row}">
              <span v-if="row.platformDistributionType == 1">金额</span>
              <span v-else-if="row.platformDistributionType == 2">比例</span>
              <span v-else></span>
            </template>
        </el-table-column>-->
        <el-table-column prop="schoolShare" label="学校分成" width="100">
          <template slot-scope="{row}">
            <span
              v-if="row.schoolShare != null && row.schoolShare != '' && row.schoolDistributionType == 1"
            >{{row.schoolShare}}元</span>
            <span
              v-else-if="row.schoolShare != null && row.schoolShare != '' && row.schoolDistributionType == 2"
            >{{row.schoolShare}}%</span>
            <span v-else>{{row.schoolShare}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="schoolDistributionType" label="类型" width="80">
            <template slot-scope="{row}">
              <span v-if="row.schoolDistributionType == 1">金额</span>
              <span v-else-if="row.schoolDistributionType == 2">比例</span>
              <span v-else></span>
            </template>
        </el-table-column>-->
        <el-table-column prop="salesmanName" label="销售人员" width="130"></el-table-column>
        <el-table-column prop="salesmanShare" label="销售分成" width="100">
          <template slot-scope="{row}">
            <span
              v-if="row.salesmanShare != null && row.salesmanShare != '' && row.salesmanDistributionType == 1"
            >{{row.salesmanShare}}元</span>
            <span
              v-else-if="row.salesmanShare != null && row.salesmanShare != '' && row.salesmanDistributionType == 2"
            >{{row.salesmanShare}}%</span>
            <span v-else>{{row.salesmanShare}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="salesmanDistributionType" label="类型" width="80">
            <template slot-scope="{row}">
              <span v-if="row.salesmanDistributionType == 1">金额</span>
              <span v-else-if="row.salesmanDistributionType == 2">比例</span>
              <span v-else></span>
            </template>
        </el-table-column>-->
        <el-table-column prop="giveVipMouth" label="赠送会员时长(天)" width="140"></el-table-column>
        <el-table-column prop="givePayerMoney" label="返利金额(元)" width="110"></el-table-column>
        <el-table-column prop="isOpen" label="是否启用" fixed="right">
          <template slot-scope="{row}">
            <el-switch
              v-if="row.id != null"
              v-model="row.isOpen"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeOpen(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="50" fixed="right">
          <template slot-scope="{row}">
            <el-row>
              <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <goodsShareInfo-detail
        v-if="showModal"
        :companyId="companyId"
        :goodsId="goodsId"
        :title="modalTitle"
        :businessKey="businessKey"
        @close="onDetailModalClose"
      ></goodsShareInfo-detail>
      <el-dialog
        title="设置销售人员"
        :visible.sync="showBatchModal"
        :close-on-click-modal="false"
        append-to-body
        width="500px"
        :modal-append-to-body="false"
        style="text-align: left;"
        element-loading-text="正在操作,请稍等"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-form label-width="150px">
          <el-form-item label="销售人员" prop="batchSalesmanId">
            <el-select v-model="batchSalesmanId" filterable placeholder="请选择" style="width:80%">
              <el-option
                v-for="salesmanInfo in salesmanInfoResult"
                :key="salesmanInfo.id"
                :label="salesmanInfo.merchantName"
                :value="salesmanInfo.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeBatchDevice">关 闭</el-button>
          <el-button type="primary" @click="handleBatchBoundDevice">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import GoodsShareInfoDetail from "./goodsShareInfo-detail";
import GoodsShareInfoBatchDetail from "./goodsShareInfo-batchDetail";
import goodsShareInfoApi from "@/api/merchant/goodsShareInfo";
import merchantInfoApi from "@/api/merchant/merchantInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["companyId", "title"],
  data() {
    var self = this;
    return {
      queryModel: {
        popedom: "",
        companyId: "",
        sceneId: "",
        sex: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      sceneId: "",
      showDialog: true,
      goodsId: "",
      showBatchModal: false,
      salesmanInfoResult: [],
      batchSalesmanId: "",
    };
  },
  created() {
    var self = this;
    var formData = new FormData();
    formData.append("type", "3");
    merchantInfoApi.list(formData).then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.salesmanInfoResult = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("companyId", self.companyId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      goodsShareInfoApi
        .schoolMGSIList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleEdit(record) {
      this.modalTitle = record.goodsName;
      this.companyId = this.companyId;
      this.sceneId = record.sceneId;
      this.goodsId = record.goodsId;
      this.businessKey = record.id;
      this.showModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showBatchModal = false;
      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    changeOpen(record) {
      goodsShareInfoApi.changeOpen(record.id);
    },
    batchSetSalesman() {
      this.showBatchModal = true;
    },
    closeBatchDevice() {
      var self = this;
      self.showBatchModal = false;
      self.batchSalesmanId = "";
    },
    handleBatchBoundDevice() {
      var self = this;

      var formData = new FormData();
      formData.append("schoolId", self.companyId);
      formData.append("salesmanId", self.batchSalesmanId);

      goodsShareInfoApi.batchUpdateSalesman(formData).then(function (response) {
        self.boundDeviceDisabled = false;
        var jsonData = response.data;
        if (jsonData.result) {
          self.$message({
            type: "success",
            message: "修改成功",
          });

          self.showBatchModal = false;
          self.batchSalesmanId = "";
          self.changePage(self.pageIndex);
        } else {
          self.$message({
            type: "warning",
            message: jsonData.message,
          });
        }
      });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "goodsShareInfo-detail": GoodsShareInfoDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>