import { render, staticRenderFns } from "./goodsShareInfo-detail.vue?vue&type=template&id=079bbae7&scoped=true&"
import script from "./goodsShareInfo-detail.vue?vue&type=script&lang=js&"
export * from "./goodsShareInfo-detail.vue?vue&type=script&lang=js&"
import style0 from "./goodsShareInfo-detail.vue?vue&type=style&index=0&id=079bbae7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079bbae7",
  null
  
)

export default component.exports